import React from 'react'
import classNames from 'classnames'

const headingClasses = `
    font-sans
    text-charcoal
    tracking-wide
    uppercase
    text-center
  `

const subtitleClasses = `
  font-sans
  text-charcoal
  text-center
  font-light
  text-lg
  md:text-2xl
  pb-2
`

const SectionHeading = ({ children, className, subtitle, thiccHeading, noUnderline }) => (
  <header className={`mb-4 ${ className || '' }`}>
    <h1 className={ classNames(headingClasses, {
      'pb-4': !!subtitle,
      'pb-0': !subtitle,
      'font-black uppercase md:text-3xl text-3xl': thiccHeading,
      'font-normal text-lg md:text-2xl': !thiccHeading && !noUnderline,
      'font-normal text-3xl': noUnderline
    }) }>{ children }</h1>
    { subtitle && <h2 className={ subtitleClasses }>{ subtitle }</h2> }
  </header>
)

export default SectionHeading
