import React from 'react'
import { storiesOf } from '@storybook/react'
import BreadCrumbs from './BreadCrumbs'

export const crumbs = [
  {
    path: '/who-we-are',
    text: 'Who we are'
  },
  {
    text: 'Our Community'
  }
]

storiesOf('General/Breadcrumbs', module)
  .add('default', () => (
    <BreadCrumbs crumbs={ crumbs } />
  ))
