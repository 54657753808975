import React from 'react'
import SectionHeading from '../Headings/SectionHeading'
import WysiwygContent from '../WysiwygContent'

const SectionIntro = ({
  title,
  subtitle,
  description,
  descriptionHtml,
  centered,
  thiccHeading,
}) => (
  <>
    <SectionHeading thiccHeading={true} subtitle={subtitle}>
      <div dangerouslySetInnerHTML={{ __html: title }}></div>
    </SectionHeading>
    {description && (
      <WysiwygContent centered={centered}>
        <p>{description}</p>
      </WysiwygContent>
    )}
    {descriptionHtml && (
      <WysiwygContent content={descriptionHtml} centered={centered} />
    )}
  </>
)

export default SectionIntro
