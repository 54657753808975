import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { rem, screen } from '../../../utils/index'

import BreadCrumbs from '../BreadCrumbs'
import SectionIntro from '../SectionIntro'
import WysiwygContent from '../WysiwygContent'

const Container = styled.div`
  ${tw`
    overflow-hidden
    mb-5
  `}

  margin: -${rem(24)};
  ${screen('xl')} {
    margin: 0;
    margin-bottom: 40px;
  }

  padding-top: 20%;
  ${screen('sm')} {
    padding-top: 16%;
  }
  ${screen('lg')} {
    padding-top: ${rem(80)};
  }
`

const ContentContainer = styled.div``

const contentContainerClasses = `
  max-w-xl
  mx-auto
`

const crumbsContainer = `
  pb-3
  lg:pb-5
`

const sectionIntroContainer = `
  px-3
`

const FullMantel = ({
  title,
  crumbs,
  description,
  centeredDesc,
  thiccHeading,
  reducedPadding,
  extraSmallText,
}) => (
  <Container>
    <div className={sectionIntroContainer}>
      <SectionIntro title={title} thiccHeading={thiccHeading} />
    </div>
    {description && (
      <ContentContainer
        className={classNames(contentContainerClasses, {
          'px-4': !reducedPadding,
          'px-2 md:px-4': reducedPadding,
          'mt-4': true,
          'text-center': centeredDesc,
        })}
      >
        <WysiwygContent
          content={description}
          centered={centeredDesc}
          extraSmallText={extraSmallText}
        />
      </ContentContainer>
    )}
  </Container>
)

export default FullMantel
