import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

const container = `
  uppercase
  font-normal
  text-center
`

const linkStyles = `
  font-sans
  no-underline
`

const BreadCrumbs = ({ crumbs }) => {
  const crumbLinks = crumbs.map((crumb, index) => {
    return crumb && crumb.path ? (
      <span key={index}>
        {' '}
        <Link
          to={crumb.path}
          className={classNames(linkStyles, {
            'text-green-3 font-bold': true,
          })}
        >
          {crumb.text}
        </Link>{' '}
        /{' '}
      </span>
    ) : (
      <span
        key={index}
        className={classNames(linkStyles, { 'text-charcoal': true })}
      >
        {crumb.text}
      </span>
    )
  })

  return <div className={container}>{crumbLinks}</div>
}

export default BreadCrumbs
